.io-download-step {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
}

.io-file-icon {
  height: 200px;
  width: 100%;
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.io-file-text {
  font-size: $field-name;
  color: $primary-color;
}
