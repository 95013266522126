.io-validate-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  .io-validate-count {
    width: 100%;
    padding: 10px;
    background-color: $background-color;
    border-radius: 10px;
    text-align: left;
  }
  .io-validate-table {
    width: 100%;
    max-height: 280px;
    background-color: $background-color;
    border-radius: 10px;
    overflow: auto;
  }
}
