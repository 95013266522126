.io-import-file-step {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  .io-import-file-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
    padding: 10px 15px;
    background-color: $background-color;
    border-radius: 10px;
    .io-file-name {
      font-size: $field-name;
      color: $primary-color;
    }
  }
  .import-error {
    width: 100%;
    text-align: left;
  }
}
