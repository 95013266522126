.io-generate-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  .io-generate-status-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background-color: $background-color;
    border-radius: 10px;
    .io-import-status {
      display: flex;
      align-items: center;
    }
  }
  .io-validate-table {
    width: 100%;
    max-height: 280px;
    background-color: $background-color;
    border-radius: 10px;
    overflow: auto;
  }
}
